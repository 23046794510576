<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Verifikasi Lapangan KL</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Nama</th>
                                          <th>Nama Surveyor</th>
                                          <th>Mulai verifikasi lapangan</th>
                                          <th>Selesai verifikasi lapangan</th>
                                          <th>Tanggal BA Verifikasi PPB KL</th>
                                          <th>Foto dengan PIC</th>
                                          <!-- <th colspan="3">Contact</th> -->
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td>{{row.nama_wilayah}}</td>
                                          <td>{{format_name(row.name_user)}}</td>
                                          <td>{{format_date(row.tanggal_mulai)}}</td>
                                          <td>{{format_date(row.tanggal_selesai)}}</td>
                                          <td>{{format_date(row.tanggal_bap_verifikasi_kl)}}</td>
                                          <td>
                                            <a v-if="row.foto_ppb != null" :href="row.foto_ppb" target="_blank">
                                                <b-img :src="row.foto_ppb" style="width: 100px;" fluid alt="Foto"></b-img>
                                            </a>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
tfoot input {
        width: 100%;
        /* padding: 3px; */
        box-sizing: border-box;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import moment from 'moment'
export default {
  data() {
      return {
        test:'hai',
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        totskor:[],
        search: '',
        headers: [
            {
            text: 'Nama',
            align: 'left',
            sortable: false,
            value: 'name',
            },
            { text: 'Ket', value: 'ket' },
            { text: 'Icon', value: 'icon' },
            { text: 'Aksi', value: '' },
            
        ],
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verifikasi Lapangan" }]);
    this.loaddata()
    
  },
  methods: {
    loaddata(){
            axios.get(this.url+"/penempatan/surveyor/kl", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.totskor = response.data.data;
                this.initDatatable();
            }).catch(error=>{
                return error
                });

            
    },
    initDatatable() {
      setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
              },
              columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
              }],
              buttons: [
                {
                  extend: 'excel',
                    text : 'Excel',
                    title: 'VERIFIKASI LAPANGAN KL KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5],
                        orthogonal: 'export',
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'VERIFIKASI LAPANGAN KL ' + date;
                    },
                },
                {
                  extend: 'pdf',
                    text : 'PDF',
                    title: 'VERIFIKASI LAPANGAN KL KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5],
                        orthogonal: 'export',
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'VERIFIKASI LAPANGAN KL ' + date;
                    },
                }
            ],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            order: [[3, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
    },
    format_name(value){
      return String(value).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>
